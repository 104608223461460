import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Image from 'gatsby-image'
import SEO from '../components/seo'

const AboutPage = ({ data }) => (
  <Layout>
    <SEO title="About" />
    <Image fluid={data.illustration.childImageSharp.fluid} />
    <div>
      <p>It’s usually difficult to say with accuracy “that’s where it began”, but for Feuery.net it was when Suzana asked us to put up a notice at the local tavern seeking ‘a nasty little gnome’ for an adventuring party.</p>
      <p>Feuery, down on her luck at the time and nettled by the job description, applied out of necessity and perversity. She’s run the show ever since.</p>
      <p>Since that start we’ve become fast friends, telling one another about our worlds whenever the opportunity allows. Feuery took some convincing that our world is all but devoid of magic and that in lieu of it we rely on ‘engines’, as is her generic term for fabricated technology.</p>
      <p>Or at least she seems to accept it – even now every so often she’ll ask some probing questions to test out whether we’re serious in what we tell her about our world and life. It is probably is hard to believe, for while we share many ideas in common (Feuery knows of ‘taxation’ for example, although she considers it applies to other people) there are others more difficult to share (Feuery just cannot believe why anyone would ‘commute’). Certainly she’s hinted a few times she suspects we’re one of the demon worlds, even if we don’t realise it.</p>
      <p>This site is your opportunity to come to know Th’eia, Feuery’s world, and those she shares it with better. We hope you will enjoy the experience!</p>
    </div>
  </Layout>
)

export default AboutPage

export const query = graphql`
  query {
    illustration: file(relativePath: { eq: "page_about.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`